<template>
  <v-container>
    <v-alert v-if="!anno" type="warning">
      Attenzione! È necessario selezionare un anno accademico.
    </v-alert>
    <div v-if="anno">
      <h1>Scrivania</h1>
      <v-progress-circular
        v-if="!loadingCardMonitoraggi"
        indeterminate
        color="primary"
      />
      <v-card elevation="8" class="mx-auto my-8" v-else>
        <v-container fluid>
          <td style="border-right: floralwhite">
            <v-card-title>Dati di Monitoraggio</v-card-title>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="v-btn"
                :to="{
                  name: 'DatiMonitoraggioView',
                  params: {
                    monitoraggi: monitoraggi,
                  },
                }"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
            </v-card-actions>
          </td>
          <td>
            <v-card-text>
              N° di programmi di orientamento <br />
              per cui sono stati confermati i dati
              <br />
              <h3>
                {{ totMonitoraggiConfermatiOggi }}/ {{ totMonitoraggiTotali }}
              </h3>
            </v-card-text>
          </td>
          <td>
            <v-card-text>
              Dati di monitoraggio <br />
              mensili al <br />
              <h2>{{ dataMonitoraggioAl }}</h2></v-card-text
            >
          </td>
        </v-container>
      </v-card>
      <v-spacer></v-spacer>
      <v-row>
        <v-col class="float-left">
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-wallet</v-icon>
              Assegnazione delle risorse e target
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile visualizzare per singolo CUP
              l'assegnazione delle risorse e dei target
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link class="v-btn" :to="`/scrivania/risorse-target`">
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="float-right">
          <v-card v-if="!isRoleDgUdm" elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-wallet</v-icon>
              Controlli
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile effettuare il controllo sul Decreto di
              concessione del finanziamento e sui rendiconti di progetto.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="!isRoleUdm"
                class="v-btn"
                :to="`/scrivania/controlli/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
              <router-link
                v-if="isRoleUdm"
                class="v-btn"
                :to="`/scrivania/udm/controlli/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isRoleRup || isRoleConsulenza" cols="6" class="float-left">
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-bank-check</v-icon>
              Dichiarazione di tracciabilità dei flussi finanziari <br />(art.3
              ss L. 136/2010)
            </v-card-title>
            <v-card-text>
              Sezione in cui il Rappresentante Legale dell'istituzione può
              inserire la dichiarazione di tracciabilità dei flussi finanziari e
              indicare il/i conto/i dedicati ai movimenti finanziari
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="isRoleRup || isRoleConsulenza"
                class="v-btn"
                :to="`/scrivania/flussi-finanziari/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          v-if="isRoleConsulenza || isRoleDgUdm"
          cols="6"
          class="float-right"
        >
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-wallet</v-icon>
              Controlli UDM
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile effettuare il controllo sul Decreto di
              concessione del finanziamento e sui rendiconti di progetto.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="isRoleConsulenza || isRoleDgUdm"
                class="v-btn"
                :to="`/scrivania/udm/controlli/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="isRoleConsulenza || (isRoleMinistero && isRoleRup)">
        <v-col>
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-account-group</v-icon> Report utenze
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile visualizzare il riepilogo delle utenze
              di Legale rappresentante e Referente di programma attualmente
              attive sul portale per ogni istituzione.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="isRoleConsulenza || (isRoleMinistero && isRoleRup)"
                class="v-btn"
                :to="`/scrivania/lista-utenze-istituzione`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MonitoraggiRepository from "@/api/ministero/MonitoraggiRepository";

export default {
  name: "ScrivaniaView",
  components: {},
  data: () => ({
    loadingCardMonitoraggi: false,
    meseMonitoraggio: null,
    dataMonitoraggioAl: null,
    dataTermineMonitoraggio: null,
    loadingProgrammi: false,
    bottoneDisabilitato: {
      check1: true,
      check2: true,
    },
    monitoraggi: null,
    totMonitoraggiConfermatiOggi: null,
    totMonitoraggiTotali: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleUdm",
      "isRoleDgUdm",
      "isRoleRup",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.getAllMonitoraggi();
      this.getDateToShow();
    },
    getDateToShow() {
      const dateOdierna = new Date();

      this.dataTermineMonitoraggio = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth(),
        10
      ).toLocaleDateString();

      // data da mostrare nella colonna data di monitoraggio al
      this.dataMonitoraggioAl = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth() - 1,
        30
      ).toLocaleDateString();
      this.meseMonitoraggio = this.dataMonitoraggioAl.split("/")[1];
    },
    async getAllMonitoraggi() {
      this.monitoraggi = await MonitoraggiRepository.getAllMonitoraggi();
      this.calcolaNumeroMonitoraggiConfermatiOggi();
    },
    /**
     * calcolo il numero di monitoraggi confermati nel mese in cui sono.
     * Se il mese è gennaio(0), i dati di monitoraggi sono riferiti a quelli di dicembre e anno precedente;
     * Se il mese è diverso da gennaio( mese > 0), allora i dati di monitoraggio sono riferiti a quelli del mese precedente e stesso anno
     */
    calcolaNumeroMonitoraggiConfermatiOggi() {
      /* calcolo il numero di monitoraggi confermati nel mese in cui sono
       */
      const date = new Date();
      const meseRiferimentoMo = date.getMonth() === 0 ? 12 : date.getMonth();
      const annoStoricizzazione =
        date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      const annoProgrmmaOrientamento = 2024;

      let totMonitoraggiConfermati = 0;
      let totMonitoraggiTotaliMese = 0;
      this.monitoraggi.forEach((element) => {
        if (
          element.anno === annoProgrmmaOrientamento &&
          element.annoStoricizzazione === annoStoricizzazione &&
          element.meseRiferimento === meseRiferimentoMo
        ) {
          totMonitoraggiTotaliMese += 1;
          totMonitoraggiConfermati += element.dataConfermaMonitoraggio ? 1 : 0;
        }
      });
      this.totMonitoraggiConfermatiOggi = totMonitoraggiConfermati;
      this.totMonitoraggiTotali = totMonitoraggiTotaliMese;
      this.loadingCardMonitoraggi = true;
    },
  },
};
</script>

<style>
.id-strutture {
  display: none;
}
</style>
