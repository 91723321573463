<template>
  <v-container fluid v-if="anno">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-main class="ma-4 pa-4">
      <v-row class="mb-8">
        <v-col cols="8">
          <h1 style="color: #1a237e">
            Dettaglio richieste integrazioni e modifiche
          </h1>
          <p>
            Visualizza i dettagli delle richieste e le risposte del soggetto
            attuatore
          </p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
      </v-row>
    </v-main>
    <div v-if="loadinPage">
      <FormInserimentoRichiestaIntegrazione
        :rendiconto="this.rendiconto"
        :read-only="readOnly"
        @richiesta-aggiunta="nuovaRichiesta += 1"
      />
      <v-row>
        <v-col cols="2">
          <TornaIndietroComponent text="Indietro" />
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
  </v-container>
  <v-container v-else>
    <alert-component
      class="text-center"
      testo="E' necessaria la selezione dell'anno accademico!"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import FormInserimentoRichiestaIntegrazione from "@/components/Controlli/VerificheRendiconti/DialogChecklistIntegrativeRequestComponent.vue";

export default {
  name: "dettagliIntegrazioniModifiche",
  components: {
    FormInserimentoRichiestaIntegrazione,
    AlertComponent,
    BreadcrumbComponent,
    RiquadroProfiloRuolo,
    TornaIndietroComponent,
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista verbali di campionamento",
            disabled: false,
            to: `/scrivania/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Verifica Rendiconto",
            disabled: true,
            to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
    nuovaRichiesta() {
      setTimeout(() => {
        this.loadinPage = false;
        this.initialize();
      }, 2000);
    },
  },
  data: () => ({
    readOnly: false,
    loadinPage: false,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    verificaRendiconto: null,
    corsiCampionati: [],
    idRendiconto: null,
    nuovaRichiesta: 0,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto && this.anno) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
      if (this.$route.query.readOnly) {
        this.readOnly = this.$route.query.readOnly;
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliRepository.getSingoloVerbaleCampionamentoRendiconto(
          parseInt(this.anno),
          this.idRendiconto,
          8
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.loadinPage = true;
      }
    },
  },
};
</script>
