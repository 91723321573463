import Repository from "../Repository";

export default {
  async getNews() {
    let url = `api/ministero/news`;
    return (await Repository.get(url)).data;
  },
  async getPrattMinistero() {
    let url = `api/ministero/pratt-ministero`;
    return (await Repository.get(url)).data;
  },
  async updatePrattMinistero(data) {
    let url = `api/ministero/pratt-ministero`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async deleteFilePrattMinistero(idFile) {
    let url = `api/ministero/pratt-ministero/delete/${idFile}`;
    return (await Repository.post(url)).data;
  },
  async chiudiPrattMinistero() {
    let url = `api/ministero/pratt-ministero/chiudi`;
    return (await Repository.post(url)).data;
  },
  async getTarget(anno) {
    let url = `api/ministero/target/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async accettaTarget(idStrutture, anno) {
    let url = `api/ministero/target/anno/${anno}/accetta/${idStrutture}`;
    return (await Repository.post(url)).data;
  },
  async rifiutaTarget(idStrutture, anno) {
    let url = `api/ministero/target/anno/${anno}/rifiuta/${idStrutture}`;
    return (await Repository.post(url)).data;
  },
  async getStruttureRaggruppate() {
    let url = `api/ministero/strutture`;
    return (await Repository.get(url)).data;
  },
  async getLegaliRappresentanti(idStrutture) {
    let url = `api/ministero/istituti/${idStrutture}/legali-rappresentanti`;
    return (await Repository.get(url)).data;
  },
  async modificaLegaleRappresentante(idStrutture, anno, formBody) {
    let url = `api/ministero/anno/${anno}/strutture/${idStrutture}/legali-rappresentanti`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async downloadPdfNominaLegaleRappresentante(idLegale, nomeOriginale) {
    const url = `api/ministero/legali-rappresentanti/${idLegale}/file-nomina`;
    const res = await Repository.get(url, { responseType: "blob" });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });

      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async scaricaTuttiDatiTrasmesiExcel(data) {
    const url = `api/ministero/programmi/dati-monitoraggio-excel`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "dati_monitoraggio.xlsx";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getUtenti(ruolo) {
    let url = `api/ministero/utenti/ruolo-controlli/${ruolo}`;
    return (await Repository.get(url)).data;
  },
  async downloadFile(anno, idFile, nomeOriginale) {
    const url = `api/ministero/anno/${anno}/orientamento-files/${idFile}`;
    const res = await Repository.get(url, { responseType: "blob" });

    if (res.status === 200) {
      const fileScaricato = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(fileScaricato);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeOriginale;
      fileLink.click();
      URL.revokeObjectURL(fileScaricato);
    } else {
      return res.data;
    }
  },
  async downloadTemplatePdfVerbale(anno, data) {
    const url = `api/ministero/anno/${anno}/genera-verbale-pdf`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "PDF_Verbale_campionamento.pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async downloadDichiarazioneConflittoInteressi(anno) {
    const url = `api/ministero/anno/${anno}/dichiarazione-assenza-conflitto-intressi`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "Dichiarazione_Assenza_conflitti_interessi";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getCostoAnnualeProgettoIstituzione(idStrutture, anno, capofila) {
    const url = `api/generici/costi-progetto/${idStrutture}/anno/${anno}/capofila/${capofila}`;

    return (await Repository.get(url)).data;
  },
};
