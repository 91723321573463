import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import TargetView from "@/views/TargetView";
import ScrivaniaView from "@/views/ScrivaniaView";
import DatiMonitoraggioView from "@/views/DatiMonitoraggioView";
import ModificaLegaleRappresentanteView from "@/views/ModificaLegaleRappresentanteView";
import ModificaIbanContoTesoreria from "@/views/ModificaIbanContoTesoreria";
import NuovoVerbaleControlloView from "@/views/Controlli/NuovoVerbaleControlloView.vue";
import DettaglioRendicontoView from "@/views/Controlli/DettaglioRendicontoView";
import ControlliView from "@/views/Controlli/ControlliView";
import IncarichiAssegnatiView from "@/views/Controlli/IncarichiAssegnatiView";
import ListaVerbaliRendiconti from "@/views/Controlli/ListaVerbaliRendiconti.vue";
import RendicontiAssegnatiView from "@/views/Controlli/RendicontiAssegnatiView";
import DettaglioCorsoView from "@/views/Controlli/DettaglioCorsoView";
import VerbaleCampionamentoView from "@/views/Controlli/VerbaleCampionamentoView";
import FinalizzaCampione from "@/views/Controlli/FinalizzaCampione.vue";
import EstendiCampioneView from "@/views/Controlli/EstendiCampioneView.vue";
import VerificaRendicontoStepUnoView from "@/views/Controlli/VerificheRendiconti/VerificaRendicontoStepUnoView.vue";
import VerificaAnagraficheRendicontoView from "@/views/Controlli/VerificheRendiconti/VerificaAnagraficheRendicontoView.vue";
import VerificaChecklistsRendicontoView from "@/views/Controlli/VerificheRendiconti/VerificaChecklistsRendicontoView.vue";
import VerificaVerbaleEsitoControlliRendicontoView from "@/views/Controlli/VerificheRendiconti/VerificaVerbaleEsitoControlliRendicontoView.vue";
import VerificaRendicontoFirmaChecklistView from "@/views/Controlli/VerificheRendiconti/VerificaRendicontoFirmaChecklistView.vue";
import VerbaleEstensioneView from "@/views/Controlli/VerificheRendiconti/VerbaleEstensioneView.vue";
import FirmaVerbaleEstensioneView from "@/views/Controlli/VerificheRendiconti/FirmaVerbaleEstensioneView.vue";
import FirmaVerbaleEsitoView from "@/views/Controlli/VerificheRendiconti/FirmaVerbaleEsitoView.vue";
import AssegnazioneRisorseTargetView from "@/views/AssegnazioneRisorseTargetView.vue";
import DettaglioRichiesteIntegrazioneModificheView from "@/views/Controlli/VerificheRendiconti/DettaglioRichiesteIntegrazioneModificheView.vue";
import ControlliUdmView from "@/views/Udm/ControlliUdmView";
import ListaVerbaliRendicontiUdm from "@/views/Udm/ListaVerbaliRendicontiUdm.vue";
//import ListaRendicontiControlloFormaleUdm from "@/views/Udm/ListaRendicontiControlloFormaleUdm.vue";
import NuovoVerbaleControlloUdmView from "@/views/Udm/NuovoVerbaleControlloUdmView.vue";
import FinalizzaCampioneUdm from "@/views/Udm/FinalizzaCampioneUdm.vue";
import EstendiCampioneUdmView from "@/views/Udm/EstendiCampioneUdmView.vue";
import VerbaleCampionamentoUdmView from "@/views/Udm/VerbaleCampionamentoUdmView";
import RendicontiAssegnatiUdmView from "@/views/Udm/RendicontiAssegnatiUdmView.vue";
import VerificaFormaleUdmStepUnoView from "@/views/Udm/VerificheFormali/VerificaFormaleUdmStepUnoView.vue";
import VerificaFormaleUdmChecklistsView from "@/views/Udm/VerificheFormali/VerificaFormaleUdmChecklistsView.vue";
import VerificaFormaleUdmFirmaChecklistView from "@/views/Udm/VerificheFormali/VerificaFormaleUdmFirmaChecklistView.vue";
import DettagliChiarimentiUdmFormaleView from "@/views/Udm/VerificheFormali/DettagliChiarimentiUdmFormaleView.vue";
import IncarichiAssegnatiUdmView from "@/views/Udm/IncarichiAssegnatiUdmView.vue";
import VerificaRendicontoUdmStepUnoView from "@/views/Udm/VerificheSostanziali/VerificaRendicontoUdmStepUnoView.vue";
import VerbaleEstensioneUdmView from "@/views/Udm/VerificheSostanziali/VerbaleEstensioneUdmView.vue";
import FirmaVerbaleEstensioneUdmView from "@/views/Udm/VerificheSostanziali/FirmaVerbaleEstensioneUdmView.vue";
import VerificaAnagraficheRendicontoUdmView from "@/views/Udm/VerificheSostanziali/VerificaAnagraficheRendicontoUdmView.vue";
import VerificaChecklistsRendicontoUdmView from "@/views/Udm/VerificheSostanziali/VerificaChecklistsRendicontoUdmView.vue";
import FirmaChecklistUdmView from "@/views/Udm/VerificheSostanziali/FirmaChecklistUdmView.vue";
import VerificaVerbaleEsitoControlliRendicontoUdmView from "@/views/Udm/VerificheSostanziali/VerificaVerbaleEsitoControlliRendicontoUdmView.vue";
import FirmaVerbaleEsitoUdmView from "@/views/Udm/VerificheSostanziali/FirmaVerbaleEsitoUdmView.vue";
import DettagliChiarimentiUdmView from "@/views/Udm/VerificheSostanziali/DettagliChiarimentiUdmView.vue";
import DettagliRisposteChiarimentiUdmView from "@/views/Controlli/VerificheRendiconti/DettagliRisposteChiarimentiUdmView.vue";
import ListaVerbaliFormaleUdm from "@/views/Udm/ListaVerbaliFormaleUdm.vue";
import DichiarazioniTracciabilitaView from "@/views/FlussiFinanziari/DichiarazioniTracciabilitaView.vue";
import DettaglioDichiarazioneView from "@/views/FlussiFinanziari/DettaglioDichiarazioneView.vue";
import DettaglioContoView from "@/views/FlussiFinanziari/DettaglioContoView.vue";
import RepoRendicontiUdm from "@/views/Udm/RepoRendicontiUdm.vue";
import UtenzeIstituzioniView from "@/views/UtenzeIstituzioni/UtenzeIstituzioniView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/sezioniPratt",
  //   name: "sezioniPratt",
  //   component: SezioniPratt,
  // },
  {
    path: "/Target",
    name: "target",
    component: TargetView,
  },
  {
    path: "/scrivania",
    name: "scrivania",
    component: ScrivaniaView,
  },
  {
    path: "/scrivania/dati-di-monitoraggio",
    name: "DatiMonitoraggioView",
    component: DatiMonitoraggioView,
    props: true,
  },
  {
    path: "/legali-rappresentanti",
    name: "modificaLegaleRappresentant",
    component: ModificaLegaleRappresentanteView,
  },
  {
    path: "/conti-bancari-istituti",
    name: "modificaIbanContoTesoreria",
    component: ModificaIbanContoTesoreria,
  },
  {
    path: "/scrivania/controlli/nuovo-controllo",
    name: "nuovoVerbaleControllo",
    component: NuovoVerbaleControlloView,
  },
  {
    path: "/scrivania/controlli/lista-rendiconti/:idStrutture/:idRendiconto/dettaglioRendiconto",
    name: "dettaglioRendiconto",
    // path: "/scrivania/controlli/lista-rendiconti/nuovo-controllo/dettaglio-rendiconti",
    // name: "DettaglioRendicontoView",
    component: DettaglioRendicontoView,
    props: true,
  },
  {
    path: "/scrivania/controlli",
    name: "ControlliView",
    component: ControlliView,
  },
  {
    path: "/scrivania/controlli/incarichi-assegnati",
    name: "IncarichiAssegnatiView",
    component: IncarichiAssegnatiView,
  },
  {
    path: "/scrivania/controlli/lista-verbali",
    name: "ListaVerbaliRendiconti",
    component: ListaVerbaliRendiconti,
    props: true,
  },
  {
    path: "/scrivania/controlli/rendiconti-assegnati",
    name: "RendicontiAssegnatiView",
    component: RendicontiAssegnatiView,
  },
  {
    path: "/scrivania/controlli/lista-rendiconti/:idStrutture/:idRendiconto/dettaglioRendiconto/:idCorso/dettaglioCorso",
    name: "dettaglioCorso",
    component: DettaglioCorsoView,
    props: true,
  },
  {
    path: "/scrivania/controlli/verbale-campionamento",
    name: "VerbaleCampionamento",
    component: VerbaleCampionamentoView,
    props: true,
  },
  {
    path: "/scrivania/controlli/finalizza-campione",
    name: "finalizzaCampione",
    component: FinalizzaCampione,
  },
  {
    path: `/scrivania/controlli/estendi-campione/:idRendiconto`,
    name: "EstendiCampione",
    component: EstendiCampioneView,
    props: true,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche`,
    name: "verificaRendicontoStepUno",
    component: VerificaRendicontoStepUnoView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche-anagrafiche`,
    name: "verificaAnagraficheRendiconto",
    component: VerificaAnagraficheRendicontoView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche-checklists`,
    name: "verificaChecklistsRendiconto",
    component: VerificaChecklistsRendicontoView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/firme-checklist`,
    name: "firmaChecklistVerificaRendiconto",
    component: VerificaRendicontoFirmaChecklistView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche-verbale-esito`,
    name: "verificaVerbaleEsitoControlliRendiconto",
    component: VerificaVerbaleEsitoControlliRendicontoView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche-verbale-estensione/:idVerbale`,
    name: "verbaleEstensione",
    component: VerbaleEstensioneView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche-verbale-estensione/:idVerbale/firma-verbale-estensione`,
    name: "firmaVerbaleEstensione",
    component: FirmaVerbaleEstensioneView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/firma-verbale-esito`,
    name: "firmaVerbaleEsito",
    component: FirmaVerbaleEsitoView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/dettagli-integrazioni-modifiche`,
    name: "dettagliIntegrazioniModifiche",
    component: DettaglioRichiesteIntegrazioneModificheView,
  },
  {
    path: `/scrivania/risorse-target`,
    name: "assegnazioneRisorseTarget",
    component: AssegnazioneRisorseTargetView,
  },
  {
    path: "/scrivania/udm/controlli",
    name: "controlliUdmView",
    component: ControlliUdmView,
  },
  {
    path: "/scrivania/udm/controlli/lista-verbali",
    name: "ListaVerbaliRendicontiUdm",
    component: ListaVerbaliRendicontiUdm,
    props: true,
  },
  {
    path: "/scrivania/udm/controlli/nuovo-controllo",
    name: "nuovoVerbaleControlloUdm",
    component: NuovoVerbaleControlloUdmView,
  },
  {
    path: "/scrivania/udm/controlli/finalizza-campione",
    name: "finalizzaCampioneUdm",
    component: FinalizzaCampioneUdm,
  },
  {
    path: `/scrivania/udm/controlli/estendi-campione/:idRendiconto`,
    name: "EstendiCampioneUdm",
    component: EstendiCampioneUdmView,
    props: true,
  },
  {
    path: "/scrivania/udm/controlli/verbale-campionamento",
    name: "VerbaleCampionamentoUdm",
    component: VerbaleCampionamentoUdmView,
    props: true,
  },
  /*{
    path: "/scrivania/udm/controlli/controllo-formale-rendiconti",
    name: "ListaRendicontiControlloFormaleUdm",
    component: ListaRendicontiControlloFormaleUdm,
    props: true,
  },*/
  {
    path: "/scrivania/udm/controlli/rendiconti-assegnati-udm",
    name: "RendicontiAssegnatiUdmView",
    component: RendicontiAssegnatiUdmView,
  },
  {
    path: `/scrivania/udm/controlli/controllo-formale-rendiconti/:idRendiconto/verifiche`,
    name: "VerificaFormaleUdmStepUnoView",
    component: VerificaFormaleUdmStepUnoView,
  },
  {
    path: `/scrivania/udm/controlli/controllo-formale-rendiconti/:idRendiconto/verifiche-checklist`,
    name: "VerificaFormaleUdmChecklistsView",
    component: VerificaFormaleUdmChecklistsView,
  },
  {
    path: `/scrivania/udm/controlli/controllo-formale-rendiconti/:idRendiconto/verifiche-checklist-firma`,
    name: "VerificaFormaleUdmFirmaChecklistView",
    component: VerificaFormaleUdmFirmaChecklistView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/dettagli-chiarimenti-udm`,
    name: "chiarimentiUdm",
    component: DettagliChiarimentiUdmView,
  },
  {
    path: `/scrivania/udm/controlli/controllo-formale-rendiconti/:idRendiconto/dettagli-chiarimenti-udm-formale`,
    name: "dettagliChiarimentiUdmFormale",
    component: DettagliChiarimentiUdmFormaleView,
  },
  {
    path: "/scrivania/udm/controlli/incarichi-assegnati-udm",
    name: "IncarichiAssegnatiUdmView",
    component: IncarichiAssegnatiUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche`,
    name: "verificaRendicontoUdmStepUno",
    component: VerificaRendicontoUdmStepUnoView,
    props: true,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche-verbale-estensione-udm/:idVerbale`,
    name: "verbaleEstensioneUdm",
    component: VerbaleEstensioneUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche-verbale-estensione-udm/:idVerbale/firma-verbale-estensione`,
    name: "firmaVerbaleEstensioneUdm",
    component: FirmaVerbaleEstensioneUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche-anagrafiche-udm`,
    name: "verificaAnagraficheRendicontoUdm",
    component: VerificaAnagraficheRendicontoUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche-checklists-udm`,
    name: "verificaChecklistsRendicontoUdm",
    component: VerificaChecklistsRendicontoUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche-checklist-firma-udm`,
    name: "firmaChecklistVerificaRendicontoUdm",
    component: FirmaChecklistUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/verifiche-verbale-esito-udm`,
    name: "verificaVerbaleEsitoControlliRendicontoUdm",
    component: VerificaVerbaleEsitoControlliRendicontoUdmView,
  },
  {
    path: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/:idRendiconto/firma-verbale-esito-udm`,
    name: "firmaVerbaleEsitoUdm",
    component: FirmaVerbaleEsitoUdmView,
  },
  {
    path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/dettagli-risposte-chiarimenti-udm`,
    name: "dettagliRisposteChiarimentiUdm",
    component: DettagliRisposteChiarimentiUdmView,
  },
  {
    path: "/scrivania/udm/controlli/controllo-formale-rendiconti",
    name: "ListaVerbaliFormaleUdm",
    component: ListaVerbaliFormaleUdm,
    props: true,
  },
  {
    path: `/scrivania/flussi-finanziari`,
    name: "dichiarazioniTracciabilitaView",
    component: DichiarazioniTracciabilitaView,
  },
  {
    path: `/scrivania/flussi-finanziari/dettaglio-dichiarazione`,
    name: "dettaglioDichiarazioneView",
    component: DettaglioDichiarazioneView,
    props: true,
  },
  {
    path: `/scrivania/flussi-finanziari/dettaglio-dichiarazione/dettaglio-conto`,
    name: "dettaglioContoView",
    component: DettaglioContoView,
    props: true,
  },
  {
    path: "/scrivania/udm/controlli/repo-rendiconti-udm",
    name: "RepoRendicontiUdm",
    component: RepoRendicontiUdm,
  },
  {
    path: "/scrivania/lista-utenze-istituzione",
    name: "utenze-istituzioni",
    component: UtenzeIstituzioniView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
