<template>
  <v-container>
    <h1>Dati di Monitoraggio</h1>
    <p>
      Lista dei programmi di orientamento con dettaglio dei Soggetti Attuatori
      che hanno confermato i dati a sistema
    </p>
    <FormFinestraCompilazione />
    <v-col sm="3" md="3">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"
        :return-value.sync="dateSelected"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Periodo di riferimento"
            v-model="dateSelected"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="it"
          v-model="dateSelected"
          @input="filterMonitoraggi"
          color="primary"
          scrollable
          type="month"
          ref="picker"
        />
      </v-menu>
    </v-col>

    <v-col sm="3" md="3">
      <a class="v-btn" @click="downloadExcel()">
        <v-icon> mdi-file-excel</v-icon>
        <span> Scarica tutti i dati trasmessi</span>
      </a>
    </v-col>
    <v-main>
      <!---<BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila"
    />-->
      <div class="pa-4" style="background-color: gainsboro">
        <v-card elevation="2" class="px-4 pb-4" :loading="filtraggioInCorso">
          <div class="mb-6">
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="monitoraggiFiltrato"
                  :items-per-page="5"
                  class="elevation-1"
                  ><!-- eslint-disable -->
                <template v-slot:item.cup="{ item }">
                  <span>{{item.programma.cup}}</span>
                </template>
                <template v-slot:item.clp="{ item }">
                  <span>{{item.programma.clp}}</span>
                </template>
                <template v-slot:item.nomeIstituzione="{ item }">
                  <span>{{item.programma.nomeIstituzione}}</span>
                </template>
                <template v-slot:item.stato="{ item }">
                  <v-chip v-if="item.dataConfermaMonitoraggio" color="success">
                    Confermato
                  </v-chip>
                  <v-chip v-else color="red accent-2">
                    Non Confermato
                  </v-chip>
                </template>
                <template v-slot:item.dataConfermaMonitoraggio="{ item }">
                  <span>{{item.dataConfermaMonitoraggio | formatDate}}</span>
                </template>
               <!--<template v-slot:no-data> nessun risultato </template>-->
                <!-- eslint-enable -->
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </v-main>
    <v-main> </v-main>
  </v-container>
</template>

<script>
//import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import { mapGetters, mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import FormFinestraCompilazione from "@/components/FormFinestraCompilazione.vue";

export default {
  name: "DatiMonitoraggioView",
  components: { FormFinestraCompilazione },
  computed: {
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Dati Monitoraggio",
          disabled: true,
          to: `/scrivania/dati-di-monitoraggio`,
          exact: true,
          link: true,
        },
      ];
    },
    ...mapState(["idStrutture", "anno", "nomeStruttura"]),
    ...mapGetters([]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.getFiltraggioMonitoraggio();
    },
    nomeStruttura() {
      this.getFiltraggioMonitoraggio();
    },
  },
  data: () => ({
    menu: false,
    month: "",
    dateSelected: "",
    loadingProgrammi: false,
    loadingPage: false,
    loading: false,
    monitoraggiFiltrato: [],
    headers: [
      { text: "CUP", value: "cup" },
      { text: "CLP", value: "clp" },
      { text: "SOGGETTO ATTUATORE", value: "nomeIstituzione" },
      { text: "STATO CONFERMA DATI", value: "stato" },
      { text: "DATA CONFERMA DATI", value: "dataConfermaMonitoraggio" },
    ],
    datiExcel: {
      cup: null,
      clp: null,
      //nomeIstituzione: null,
      stato: null,
      dataConfermaMonitoraggio: null,
    },
    filtraggioInCorso: false,
  }),
  created() {
    this.initialize();
  },
  props: {
    monitoraggi: {
      type: Array,
      required: true,
    },
  },
  methods: {
    initialize() {
      if (this.anno && this.monitoraggi.length) {
        this.getFiltraggioMonitoraggio();
      }
    },
    getFiltraggioMonitoraggio() {
      this.filterMonitoraggi(null);
      this.loadingProgrammi = false;
    },
    async downloadExcel() {
      let datiExcel = { ...this.monitoraggiFiltrato };
      const data = new FormData();
      data.append("datiExcel", JSON.stringify(datiExcel));
      await MinisteroRepository.scaricaTuttiDatiTrasmesiExcel(data);
    },
    filterMonitoraggi(dateStr) {
      this.dateSelected = dateStr;
      this.filtraggioInCorso = true;
      let meseRiferMonitoraggi = null;
      let annoRiferMonitoraggi = null;
      let annoStoricizzazioneRiferMonitoraggi = null;
      if (dateStr) {
        this.$refs.menu.save(dateStr);
        meseRiferMonitoraggi =
          parseInt(dateStr.split("-")[1]) === 1
            ? 12
            : parseInt(dateStr.split("-")[1]) - 1;
        annoRiferMonitoraggi =
          meseRiferMonitoraggi === 12
            ? parseInt(dateStr.split("-")[0]) - 1
            : parseInt(dateStr.split("-")[0]);
        annoStoricizzazioneRiferMonitoraggi = annoRiferMonitoraggi;
      }
      /**
       * 1) se il mese è gennaio, allora filtro i dati considerando il dicembre dell'anno precedente rispetto a quello selezionato;
       * 2) Se il mese NON è gennaio, filtro i dati di monitoraggio considerando il mese precedente dell'anno selezionato .
       * 3) In entrambi i casi filtro i dati considerando anche l'anno del programma di orientamento e l'id strutture dei menu a tendina in alto
       */
      this.monitoraggiFiltrato = this.monitoraggi.filter((monitoraggio) => {
        if (dateStr) {
          // ho selezionato l'anno e il mese di riferimento di mio interesse, quindi devo estrapolare i monitoraggi di un mese specifico
          return (
            monitoraggio.meseRiferimento === meseRiferMonitoraggi &&
            monitoraggio.annoStoricizzazione ===
              annoStoricizzazioneRiferMonitoraggi &&
            (this.anno !== null ? monitoraggio.anno === this.anno : 1 === 1) &&
            (this.idStrutture !== null
              ? monitoraggio.idStrutture === this.idStrutture
              : 1 === 1)
          );
        } else {
          // filtro solo per anno e idStrutture, se settati nei due menu a tendina in alto
          return (
            (this.anno !== null ? monitoraggio.anno === this.anno : 1 === 1) &&
            (this.idStrutture !== null
              ? monitoraggio.idStrutture === this.idStrutture
              : 1 === 1)
          );
        }
      });
      this.filtraggioInCorso = false;
    },
  },
};
</script>
<style scoped>
.mb-1 {
  min-height: 5px;
  padding: 20px;
  max-width: 86%;
}
</style>
