<template>
  <v-card elevation="4">
    <v-card-title> Riepilogo finanziario </v-card-title>
    <v-card-text>
      <v-data-table :items="risorseFinanziarieFiltered" :headers="headers">
        <!-- eslint-disable -->
        <template v-slot:item.assegnazioneIniziale="{ item }">
            <!--<span v-if="item.assegnazioneAggiuntiva">
              {{ getFormattedAmount(item.programmaOrientamento.assegnazioneFinanziaria - item.assegnazioneAggiuntiva)}}
            </span>-->
          <span>
            {{ getFormattedAmount(item.programmaOrientamento.assegnazioneFinanziaria)}}
          </span>
        </template>
        <template v-slot:item.assegnazioneAggiuntiva="{ item }">
          <span v-html="item.assegnazioneAggiuntiva ? getFormattedAmount(item.assegnazioneAggiuntiva) : '0 €'" />
        </template>
        <template v-slot:item.incrementoImportoAssegnato="{ item }">
          <span> {{ getFormattedAmount(item.assegnazioneAggiuntiva) }}</span>
        </template>
        <template v-slot:item.importoRichiestaAnticipo="{ item }">
          <span> {{ item.idStatoRichiesta && item.idStatoRichiesta === 2 ? getFormattedAmount(item.importoRichiestaAnticipo) : '0 €' }} </span>
        </template>
        <template v-slot:item.importoRendicontato="{ item }">
          <a @click="apriDialogDettaglioRendicontazioni(item)">
            {{ getFormattedAmount(item.importoRendicontato) }}
          </a>
        </template>
        <template v-slot:item.importoAmmessoDG="{ item }">
          <span> {{ getFormattedAmount(item.importoAmmessoDG) }}</span>
        </template>
        <template v-slot:item.importoAmmessoUDM="{ item }">
          <span> {{ getFormattedAmount(item.importoAmmessoUDM) }} </span>
        </template>
        <template v-slot:item.importoDaControllareDG="{ item }">
          <span> {{ getFormattedAmount(item.importoDaControllareDG)}} </span>
        </template>
        <template v-slot:item.importoDaControllareUDM="{ item }">
          <span> {{ getFormattedAmount(item.importoDaControllareUDM)}}</span>
        </template>
        <template v-slot:item.importoDP="{ item }">
          <span> {{ getFormattedAmount(item.importoDP)}}</span>
        </template>
        <template v-slot:item.importoComplessivoDaPagare="{ item }">
          <span v-html="calcolaImportoDaPagare(item)" />
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-if="dialogDettaglioRendicontazioni"
      v-model="dialogDettaglioRendicontazioni"
      hide-overlay
      fullscreen
      transition="dialog-bottom-transition"
    >
      <DettaglioRendicontazioni
        :capofila="dettaglioRendicontazioniProps.capofila"
        :anno="dettaglioRendicontazioniProps.anno"
        :id-strutture="dettaglioRendicontazioniProps.idStrutture"
        @chiusuraDialog="chiudiDialodDettaglioRendicontazioni"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import DettaglioRendicontazioni from "@/components/AssegnazioneTargetControlli/DialogDettaglioRendicontazioniComponent.vue";

export default {
  name: "riepilogoDueAssegnazioneRisorse",
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.getFilteredData();
    },
    anno() {
      this.getFilteredData();
    },
  },
  components: { DettaglioRendicontazioni },
  created() {
    if (this.risorseFinanziarie) {
      this.getFilteredData();
    }
  },
  props: {
    risorseFinanziarie: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: "CUP", value: "programmaOrientamento.cup", align: "start" },
      { text: "ISTITUZIONE", value: "programmaOrientamento.nomeIstituzione" },
      { text: "ASSEGNAZIONE INIZIALE", value: "assegnazioneIniziale" },
      {
        text: "ASSEGNAZIONE AGGIUNTIVA",
        value: "assegnazioneAggiuntiva",
      },
      { text: "IMPORTO ANTICIPO EROGATO", value: "importoRichiestaAnticipo" },
      {
        text: "IMPORTO RENDICONTATO IN RENDICONTI PRECEDENTI",
        value: "importoRendicontato",
      },
      {
        text: "IMPORTO AMMESSO IN RENDICONTI PRECEDENTI - DG",
        value: "importoAmmessoDG",
      },
      {
        text: "IMPORTO AMMESSO IN RENDICONTI PRECEDENTI - UDM",
        value: "importoAmmessoUDM",
      },
      {
        text: "IMPORTO RENDICONTATO DA CONTROLLARE - DG",
        value: "importoDaControllareDG",
      },
      {
        text: "IMPORTO RENDICONTATO DA CONTROLLARE - UDM",
        value: "importoDaControllareUDM",
      },
      {
        text: "DISPOSIZIONI PAGAMENTO SAP",
        value: "importoDP",
      },
      {
        text: "IMPORTO DA PAGARE COMPLESSIVO",
        value: "importoComplessivoDaPagare",
        sortable: false,
      },
    ],
    risorseFinanziarieFiltered: [],
    dialogDettaglioRendicontazioni: false,
    dettaglioRendicontazioniProps: {
      idStrutture: null,
      capofila: null,
      anno: null,
    },
  }),
  methods: {
    getFilteredData() {
      this.risorseFinanziarieFiltered = this.risorseFinanziarie.filter(
        (element) => {
          return (
            (this.idStrutture != null
              ? element.programmaOrientamento.idStrutture === this.idStrutture
              : element.programmaOrientamento.idStrutture > 0) &&
            (this.anno != null
              ? element.programmaOrientamento.anno === this.anno
              : element.programmaOrientamento.anno > 0)
          );
        }
      );
    },
    getFormattedAmount(importo) {
      if (!importo) return 0 + " €";
      return (
        parseFloat(importo).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " €"
      );
    },
    async getFileToDownload(anno, nomeOriginale, idFile) {
      await MinisteroRepository.downloadFile(anno, idFile, nomeOriginale);
    },
    apriDialogDettaglioRendicontazioni(object) {
      this.dettaglioRendicontazioniProps.capofila =
        object.programmaOrientamento.capofila;
      this.dettaglioRendicontazioniProps.anno =
        object.programmaOrientamento.anno;
      this.dettaglioRendicontazioniProps.idStrutture =
        object.programmaOrientamento.idStrutture;

      this.dialogDettaglioRendicontazioni = true;
    },
    chiudiDialodDettaglioRendicontazioni() {
      this.dettaglioRendicontazioniProps.idStrutture = null;
      this.dettaglioRendicontazioniProps.anno = null;
      this.dettaglioRendicontazioniProps.capofila = null;

      this.dialogDettaglioRendicontazioni = false;
    },
    calcolaImportoDaPagare(importiIstituzione) {
      let importoComplessivoDaPagare =
        (importiIstituzione.importoAmmessoDG ?? 0) -
        (importiIstituzione.importoDP ?? 0);

      return this.getFormattedAmount(importoComplessivoDaPagare);
    },
  },
};
</script>
